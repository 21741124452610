import React from 'react';
import styled from 'styled-components';

import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Container } from '../../layout/Container';
import { Button } from '../../ui/button/Button';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import Space from '../../ui/space/Space';
import { Seo } from '../../../utils/seo/Index';

const Budrunden: React.FC = () => {
  return (
    <>
      <Seo
        title="PrivatMegleren - Ha Finansieringsbevis Klart Før Visning: forbered deg til budrunden"
        description="Sørg for å ha finansieringen klar før visning for en smidig budrunde. Les våre tips om hvordan du forbereder deg best mulig, inkludert de formelle kravene som gjelder ved budgivning. Nordea hjelper deg med finansiering. Søk finansiering hos Nordea i dag."
        image="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Sofa-med-transparent-overgang.jpg"
        url="https://privatmegleren.no/budrunden"
      />
      <Space style={{ paddingTop: '9em' }} />
      <BudrundenStyle>
        <Container>
          <Heading style={{ textAlign: 'center ', margin: '0' }} tag="h1">
            Nyttig å vite til budrunden
          </Heading>
          <Space style={{ paddingBottom: '5em' }} />
          <Heading className="title" tag="h3" center={true}>
            Ha finansieringsbevis klart tidlig
          </Heading>
          <Paragraph className="text" center>
            Det er kort tid fra siste visning til budrunden starter. Sørg for å
            ha finansieringen klar før visning, så har du det økonomiske på
            plass hvis du finner en eiendom du ønsker å kjøpe. Les mer om
            finansiering{' '}
            <a href="https://privatmegleren.no/finansiering-boliglan">her</a> .
          </Paragraph>
          <Paragraph className="text" center>
            Nordea kan hjelpe deg med finansiering.
          </Paragraph>
          <Button
            className="nordeabtn"
            type="primary"
            colorTheme="gold"
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.location.href =
                  'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html?cid=partner-jhoqy925s1';
              }
            }}
            fullWidth={false}
          >
            Søk om finansiering fra Nordea
          </Button>
          <Heading className="title" tag="h3" center={true}>
            Lurt å gjøre før du byr
          </Heading>
          <Paragraph className="text" center>
            Sett deg inn i all informasjon om eiendommen. Les salgsoppgaven,
            inkludert eventuelle tekniske rapporter, og gjør gjerne en research
            på sammenlignbare eiendommer. Spør megler hvis du lurer på noe.
          </Paragraph>
          <Heading className="title" tag="h3" center={true}>
            Sett et tak for hvor høyt du kan gå, og hold deg til dette
          </Heading>
          <Paragraph className="text" center>
            Gjør din egen subjektive vurdering av hvilket maksbeløp eiendommen
            er verdt for deg. Det er enkelt å la seg rive med i en budrunde.
            Bestem deg på forhånd hva som er maksbudet ditt på enhver eiendom du
            ser for deg å by på. Maksbeløpet bør ikke overstige det du har
            finansiering til.
          </Paragraph>
          <Heading className="title" tag="h3" center={true}>
            Skriftlige og forpliktende bud
          </Heading>
          <Paragraph className="text" center>
            Første bud skal være skriftlig. Ofte skjer dette digitalt gjennom
            meglers system. Her legges også inn eventuelle forbehold du måtte ha
            for budet. Et bud på kjøp av eiendom er bindende. Du kan derfor ikke
            by på flere eiendommer samtidig.
          </Paragraph>
          <Heading className="title" tag="h3" center={true}>
            Gi bud i god tid før akseptfrist
          </Heading>
          <Paragraph className="text" center>
            Selger og megler trenger litt tid til å vurdere et bud før fristen
            utløper. Det vanlige er fem til ti minutter margin før fristen for å
            sikre at selgers aksept når budgiveren i tide. Som budgiver er det
            viktig å legge til ekstra tid for å unngå at en annens bud blir
            akseptert før ditt bud legges inn.
          </Paragraph>
          <Heading className="title" tag="h3" center={true}>
            Informer banken ved budaksept
          </Heading>
          <Paragraph className="text" center>
            Vinner du en budrunde, må du informere banken og kjøpekontrakt
            oversendes så fort den er signert. Deretter vil en rådgiver fra
            banken kontakte deg for å konvertere finansieringsbeviset til
            boliglån og veilede deg gjennom låneprosessen, inkludert signering
            av nødvendig dokumentasjon.
          </Paragraph>
          <Paragraph className="text" center>
            Har du ikke finansieringsbeviset klart? Søk finansiering hos Nordea{' '}
            <a href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/jeg-onsker-a-kjope-en-bolig.html?cid=partner-jhoqy925s1">
              her
            </a>
            .
          </Paragraph>
          s
        </Container>

        <BackgroundLoad
          opacity={0.8}
          url="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/Sofa-med-transparent-overgang.jpg"
          showOnMobile
        />
      </BudrundenStyle>
    </>
  );
};

const BudrundenStyle = styled.div`
  .title {
    text-align: left;
  }
  .text {
    text-align: left;
  }
  .nordeabtn {
    justify-content: flex-start !important;
  }
`;

export default Budrunden;
